// We do this so that we can place an SVG inside of a div and have the height / width
// of the SVG still apply. Without this, the SVG will be `display: inline;` and the height / width
// attributes will not apply.
svg {
  display: block;
}

.svg-sm {
  &svg,
  svg {
    width: var(--font-size-sm);
    height: var(--font-size-sm);
  }
}

svg.svg-lg,
.svg-lg svg {
  width: var(--font-size-lg);
  height: var(--font-size-lg);
}

svg.svg-xl,
.svg-xl svg {
  width: var(--font-size-xl);
  height: var(--font-size-xl);
}

svg.svg-15rem,
.svg-15rem svg {
  width: 1.5rem;
  height: 1.5rem;
}

svg.svg-2rem,
.svg-2rem svg {
  width: 2rem;
  height: 2rem;
}
