.zm-container {
  max-width: var(--max-width);
  margin: 0 auto;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.strikethrough {
  text-decoration: line-through;
}

pre,
code,
.mono {
  font-family: var(--mono-font);
}

.rounded {
  border-radius: var(--border-radius);
}

.overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.overflow-hidden {
  overflow: hidden;
}

svg {
  width: 1rem;
  height: 1rem;
}

.with-svg {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 1rem;
  }

  &.svg-left {
    svg {
      margin-right: 0.5rem;
    }
  }

  &.svg-right {
    svg {
      margin-left: 0.5rem;
    }
  }
}

.well {
  background-color: var(--opacity-light);
  border-radius: var(--border-radius);
  padding: 1rem 1.25rem;
}

.empty-wrapper {
  padding: 3rem;
  text-align: center;

  .empty-icon-wrapper {
    display: inline-flex;
    background-color: var(--brand-pale-bg-color);
    border-radius: 150px;
    margin: 2rem 0;
    margin-top: 1rem;
    width: 90px;
    height: 90px;
    align-items: center;
    justify-content: space-around;

    svg {
      width: 3rem;
      height: 3rem;
      color: var(--brand-color);
    }
  }

  .action-btn {
    display: inline-flex;
  }
}

a,
.a {
  color: var(--brand-color);
  cursor: pointer;
  transition: color var(--transition-duration) var(--transition-fn);

  &:hover {
    text-decoration: none;
    color: var(--brand-active-color);
  }
}

a.text-primary,
.a.text-primary {
  color: var(--primary-color);

  &:hover {
    color: var(--brand-active-color);
  }
}

@media (max-width: 650px) {
  .empty-wrapper {
    padding: 2rem;

    .empty-icon-wrapper {
      margin: 1.5rem 0;
      margin-top: 1rem;
      width: 80px;
      height: 80px;

      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    h4 {
      font-size: 1.25rem;
    }
  }
}

@media (max-width: 450px) {
  .empty-wrapper {
    padding: 1rem;
    padding-bottom: 1.5rem;
    font-size: var(--font-size-sm);

    .empty-icon-wrapper {
      margin-top: 1rem;
    }

    h4 {
      font-size: 1.1rem;
    }
  }
}

.mirror-content {
  word-break: break-word;

  h1 {
    font-size: 1.5rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    font-weight: var(--font-weight-bold);
  }

  h2 {
    font-size: 1.25rem;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    font-weight: var(--font-weight-bold);
  }

  // The add block menu trigger can appear before the first h node in the document.
  .add-block-menu:first-child + h1,
  .add-block-menu:first-child + h2,
  h1:first-child,
  h2:first-child {
    margin-top: 0;
  }

  blockquote {
    margin-inline-start: 1rem;
    margin-inline-end: 1rem;
  }

  pre {
    padding: 1rem;
    border-radius: var(--border-radius);
    background-color: var(--secondary-bg-color);
  }

  // This is the style for the Image node. We use <img />s in other nodes so we are purposefully
  // selecting .image rather than <img />.
  .image {
    display: block;
    max-width: 100%;
    max-height: 25rem;
    margin: 1rem auto;
  }

  ul,
  ol,
  p {
    margin-bottom: 1.25rem;
  }

  ul,
  ol {
    padding-inline-start: 2rem;
  }

  li p,
  *:last-child {
    margin-bottom: 0;
  }

  blockquote {
    border-left: 3px solid var(--brand-color);
    padding: 0 1rem;
  }

  hr {
    margin: 0 0 1.25rem 0;
    border-bottom: 1px solid var(--divider-color);
  }

  .variable {
    color: var(--brand-color);
    background-color: var(--brand-pale-bg-color);
    border-radius: var(--small-border-radius);
    padding: 0 0.25rem;
  }
}

.bg-primary {
  background-color: var(--primary-bg-color);
}
.bg-primary-elevated {
  background-color: var(--elevated-primary-bg-color);
}
.bg-hover {
  transition: background-color var(--transition-duration) var(--transition-fn);
}
.bg-hover:hover {
  background-color: var(--hover-bg-color);
}
.bg-secondary {
  background-color: var(--secondary-bg-color);
}

.hidden {
  display: none;
}

.grid-background {
  background-image: radial-gradient(
    var(--darker-pale-gray) 0.5px,
    transparent 0px
  );
  background-size: 8px 8px;
  position: fixed;
}

.ff-safe-translucent-primary-bg {
  background-color: var(--primary-bg-color-translucent);
  backdrop-filter: blur(10px);

  @supports (-moz-appearance: none) {
    background-color: var(--primary-bg-color);
  }
}
