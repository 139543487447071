.theme-root,
.tint-root {
  --nav-bg-color: var(--secondary-bg-color);
  --nav-bg-color-translucent: var(--secondary-bg-color-translucent);
  --nav-bg-color-transparent: var(--secondary-bg-color-transparent);
  --nav-item-color: var(--primary-color);
  --nav-item-hover-color: var(--primary-color);
  --nav-item-hover-bg-color: var(--tertiary-bg-color);
  --nav-item-selected-color: var(--brand-color);

  --tab-item-color: var(--secondary-color);
  --tab-item-hover-color: var(--primary-color);
  --tab-item-selected-color: var(--brand-color);

  --nav-link-color: var(--tertiary-color-alpha);
  --nav-link-hover-color: var(--primary-color);

  --nav-vertical-padding: 0.75rem;
  --nav-horizontal-padding: 1rem;
  --nav-padding: var(--nav-vertical-padding) var(--nav-horizontal-padding);

  --page-header-tab-color: var(--tertiary-color-alpha);
  --page-header-tab-color-hover: var(--secondary-color-alpha);
  --page-header-tab-color-selected: var(--primary-color);
}
