.event-theme {
  --page-bg: var(--depressed-secondary-bg-color);
  --translucent-overlay-bg-color: var(--elevated-secondary-bg-color);
  --card-bg-color: var(--elevated-primary-bg-color);
  --card-bg-color-opaque: var(--card-bg-color);
  --card-backdrop-filter: none;

  &:not(.tinted) {
    --tint-color: var(--secondary-color-alpha);
  }

  .text-tinted {
    color: var(--tint-color);
  }
}

.theme-root .event-theme.tinted {
  --page-bg: var(--brand-10, var(--depressed-secondary-bg-color));
  --primary-button-bg-color: var(--brand-50, var(--gray-90));
  --primary-button-hover-bg-color: var(--brand-40, var(--gray-80));
}

.theme-root.dark .event-theme.tinted {
  --page-bg: var(--brand-80, var(--depressed-secondary-bg-color));
  --card-bg-color: var(--brand-70, var(--elevated-primary-bg-color));

  --primary-button-bg-color: var(--white);
  --primary-button-hover-bg-color: var(--gray-20);
  --primary-bg-color: var(--brand-80);
}

.theme-root .event-theme.nebula {
  --page-bg: url("https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,quality=85,width=353,height=303/misc/0n/b4874879-87dd-44a4-9ae7-32176a8122d9");
  --card-bg-color: #fffb;
  --card-bg-color-opaque: #fff;
  --card-backdrop-filter: blur(16px);
}
